import React from "react";
import CustomTitle from "../CustomTitle";
import "./OvulationInduction.css";
import simg from "../assets/img/Treatments/subbanner/Ovulation-Induction.jpg";
import { useLocation, Link } from "react-router-dom";
export default function Services() {
  const helmetDescriptions = {
    titleName:
      "Ovulation Induction Fertility Treatment | Ovulation Induction Treatment",
    Description:
      "Ovulation induction treatment is used to induce ovulation in women. It is a non-invasive and highly cost effective treatment. Cosult our experts today.!",
  };
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const ovulationIndunctionSchemaCode = `<script type="application/ld+json">
  {
    "@context": "http://schema.org/",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What drugs are used in Ovulation induction ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Commonly used drugs are Clomiphene citrate, Letrozole. Injectables such as FSH are also used. Inj HCG may be used for ovulation."
        }
      },
      {
        "@type": "Question",
        "name": "Side effects of ovulation induction ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Commonly patients may have slight nausea, vomiting, bloating, headaches, pelvic pain. Very rarely blurring of vision"
        }
      },
      {
        "@type": "Question",
        "name": "How many times ovulation induction can be done ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Usually a maximum of 6 cycles of induction are done in a couple.This is subject to vary, depending upon the couple history."
        }
      },
      {
        "@type": "Question",
        "name": "Is Ovulation induction successful ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "On an average success with Ovulation induction is around 20-30% pregnancy per cycle. Success rate is higher with IVF/ICSI."
        }
      },
      {
        "@type": "Question",
        "name": "Can IUI be done with Ovulation induction?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, IUI can be done with ovulation induction. Results are better when IUI is done along with ovulation induction."
        }
      },
      {
        "@type": "Question",
        "name": "Who could benefit from ovulation induction?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "On an average success with Ovulation induction is around 20-30% pregnancy per cycle. Success rate is higher with IVF/ICSI."
        }
      },
      {
        "@type": "Question",
        "name": "Can IUI be done with Ovulation induction?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Women, with ovulation problems, can benefit from ovulation induction. Risks and side effects of ovulation induction? The risks and side effects of ovulation induction are bloating, nausea, headaches, loss of breath, hot flashes, weight gain, tenderness in the pelvic region, and blurry vision."
        }
      },
      {
        "@type": "Question",
        "name": "What are the benefits of ovulation induction?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "This procedure improves the growth and release of eggs for fertilization in women with ovulation problems. When given as part of the IVF cycle, ovulation stimulation helps to mature multiple eggs in a single cycle, thus increasing the chances of pregnancy."
        }
      }
    ]
  }
  </script>
  
  `;
  const Accodian = [
    {
      id: 1,
      question: "What drugs are used in Ovulation Induction ?",
      answer:
        " Commonly used drugs are Clomiphene citrate, Letrozole. Injectables such as FSH are also used. Inj HCG may be used for ovulation.",
    },
    {
      id: 2,
      question: "Side effects of Ovulation Induction ?",
      answer:
        " Commonly patients may have slight nausea, vomiting, bloating, headaches, pelvic pain. Very rarely blurring of vision.",
    },
    {
      id: 3,
      question: "How many times Ovulation Induction can be done ?",
      answer:
        "  Usually a maximum of 6 cycles of induction are done in a couple.This is subject to vary, depending upon the couple history.",
    },
    {
      id: 4,
      question: "Is Ovulation Induction successful ?",
      answer:
        " On an average success with Ovulation Induction is around 20-30% pregnancy per cycle. Success rate is higher with IVF/ICSI.",
    },
    {
      id: 5,
      question: "Can IUI be done with Ovulation Induction?",
      answer:
        " Yes, IUI can be done with Ovulation Induction. Results are better when IUI is done along with ovulation induction.",
    },
    {
      id: 6,
      question: "Who could benefit from Ovulation Induction?",
      answer:
        " On an average success with Ovulation Induction is around 20-30% pregnancy per cycle. Success rate is higher with IVF/ICSI.",
    },
    {
      id: 7,
      question: "Can IUI be done with Ovulation Induction?",
      answer:
        "Women, with ovulation problems, can benefit from ovulation induction. Risks and side effects of ovulation induction? The risks and side effects of ovulation induction are bloating, nausea, headaches, loss of breath, hot flashes, weight gain, tenderness in the pelvic region, and blurry vision.",
    },
    {
      id: 8,
      question: "What are the benefits of Ovulation Induction?",
      answer:
        "This procedure improves the growth and release of eggs for fertilization in women with ovulation problems. When given as part of the IVF cycle, ovulation stimulation helps to mature multiple eggs in a single cycle, thus increasing the chances of pregnancy.",
    },
  ];

  return (
    <div>
      <CustomTitle
        href="https://kangaroocarefertility.com/treatments/ovulation-induction"
        customValue={helmetDescriptions}
      />
      <iframe
        srcDoc={ovulationIndunctionSchemaCode}
        title="schema"
        width="0"
        height="0"
      />
      <div className="main-content container mt-2">
        <nav aria-label="breadcrumb" className="mb-5">
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Ovulation Induction
            </li>
          </ol>
        </nav>
      </div>
      {/* -------main content------------ */}

      {/* -----------------------------what is portion -------------------- */}
      <div className="container-fluid service my-5">
        <div className="row justify-content-center">
          <h1 className="heading1 text-center pb-5">
            Ovulation Induction Fertility Treatment
          </h1>
          <div className="col-md-6 mx-2 d-none d-sm-block">
            <div className="mb-2" id="service-left-img-ov">
              <img src={simg} alt="pregnant women baby bump" />
            </div>
          </div>
          <div className="col-md-5 mb-2 mx-2" id="service-content-card">
            <h2 className="my-4 text-center text-blue">Ovulation Induction</h2>
            <hr />
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>What is Ovulation Induction?</strong>
            </p>
            <p>
              Ovulation Induction involves giving medicines to induce ovulation
              in women, may be followed by follicular monitoring by scan. Couple
              are advised regarding timed intercourse or artificial
              insemination.
            </p>
          </div>
        </div>

        {/* -----------------------section2 content process--------------------- */}

        <div className="row justify-content-center mt-5">
          <div className="col-md-8 mx-2" id="service-content2-card">
            <div className="mb-2 p-4">
              <h2 className="text-center text-blue">
                Process of Ovulation Induction
              </h2>
              <br />
              <p>
                At Kangaroo Care Fertility, our experts carry out the below
                Ovulation Induction process to provide the best possible
                outcome.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>
                <strong>Assessment - </strong>We asses the woman’s ovulation
                cycle with blood tests and/or an ultrasound to see the
                development of follicles in the ovaries and thickness and
                appearance of the uterus lining
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>
                <strong>Stimulation - </strong>Ovaries are stimulated with
                carefully selected medication to promote the growth of follicles
                containing eggs.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>
                <strong>Monitoring - </strong> Done very closely with ultrasound
                and/or blood tests to check the number and size of follicles
                growing.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>
                <strong>Timed intercourse or artificial insemination - </strong>
                Around the time of ovulation we shall advise the most
                appropriate day to have sexual intercourse to maximise the
                chances of pregnancy or to schedule an intrauterine
                insemination.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>
                <strong>Testing for Pregnancy - </strong>Your doctor will
                provide you with the date (around 2 weeks after ovulation) on
                which you will test your pregnancy with a simple UPT kit.
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mb-2 mx-2 p-4 card  d-none d-md-block"
            id="service-link-card"
          >
            <h2 className="text-blue">Important Links</h2>
            <br />
            <ul className="ms-5">
              <li>
                <Link to="/treatments/ovulation-induction">
                  <span className="hand-link">&#9758;</span> &nbsp; Ovulation
                  Induction
                </Link>
              </li>
              <li>
                <Link to="/treatments/intrauterine">
                  <span className="hand-link">&#9758;</span> &nbsp; Intrauterine
                  Insemination (IUI)
                </Link>
              </li>
              <li>
                <Link to="/treatments/invitro-fertilization">
                  <span className="hand-link">&#9758;</span> &nbsp; In Vitro
                  Fertilization
                </Link>
              </li>
              <li>
                <Link to="/treatments/intracytoplasmic">
                  <span className="hand-link">&#9758;</span> &nbsp;
                  Intracytoplasmic Sperm Injection
                </Link>
              </li>
              <li>
                <Link to="/treatments/genetic-counselling-and-testing">
                  <span className="hand-link">&#9758;</span> &nbsp; Genetic
                  Counselling and Testing
                </Link>
              </li>
              <li>
                <Link to="/treatments/varicocele">
                  <span className="hand-link">&#9758;</span> &nbsp; Varicocele
                </Link>
              </li>
              <li>
                <Link to="/treatments/tesa-pesa">
                  <span className="hand-link">&#9758;</span> &nbsp; TESA/ PESA
                </Link>
              </li>
              <li>
                <Link to="/treatments/fertility-preservation">
                  <span className="hand-link">&#9758;</span> &nbsp; Fertility
                  Preservation
                </Link>
              </li>
              <li>
                <Link to="/treatments/Donor">
                  <span className="hand-link">&#9758;</span> &nbsp; Donor
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* -------------------------advantage cards---------------- */}

      <div className="container-fluid my-4">
        <div className="row justify-content-center" id="scards">
          <h2 className="text-center text-blue">
            Advantages of Ovulation Induction
          </h2>
          <p style={{ width: "90%", textAlign: "center" }}>
            We have an expert team of fertility specialists to provide the best
            Ovulation Induction treatment in Vijayanagar. We aim to provide
            affordable, non-invasive & suitable treatment to increase the
            chances of conceiving.
          </p>
          <br />
          <br />
          <div className="col-sm-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              {/* <img src={logo} alt="" /> */}
              <p>
                <strong className="text-blue">Affordable</strong>
              </p>
            </div>
            <p>
              Ovulation Induction involves oral medications and sometimes
              injections and thus is not expensive.
            </p>
          </div>
          <div className="col-sm-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              {/* <img src={logo} alt="" /> */}
              <p>
                <strong className="text-blue">Non-Invasive</strong>
              </p>
            </div>
            <p>It does not involve any invasive procedures.</p>
          </div>
          <div className="col-sm-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              {/* <img src={logo} alt="" /> */}
              <p>
                <strong className="text-blue">Suitability</strong>
              </p>
            </div>
            <p>
              It is most suitable in women with PCOS, An ovulation,
              Mild-moderate Endometriosis, and unexplained infertility.
            </p>
          </div>
        </div>
      </div>

      {/* ---------------------------faq------------------------ */}

      <div className="accordion container-fluid my-5 " id="accordionExample">
        <h2 className="text-center text-blue">FAQs</h2>
        <br />
        {Accodian.map((item) => {
          return (
            <>
              <div className="accordion-item">
                <h2 className="accordion-header" id={`heading${item.id}`}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${item.id}`}
                    aria-expanded="false"
                    aria-controls={`collapse${item.id}`}
                  >
                    • {item.question}
                  </button>
                </h2>
                <div
                  id={`collapse${item.id}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`heading${item.id}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">{item.answer}</div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      {/* {---------------seo info----------------} */}
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Why choose Kangaroo Care Fertility for Ovulation Induction
                Treatment in Bangalore?
              </h2>
              <p>
                Kangaroo Care Fertility is one of the best fertility centres in
                Vijayanagar Bangalore to provide a wide range of fertility
                services like IVF, IUI, and ICSI which makes us unique from
                other hospitals. We aim to provide the best female infertility
                treatment in Bangalore.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* ----------------some heading and para----------- */}
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Ovulation Induction Treatment in Bangalore
              </h2>
              <p>
                At Kangaroo Care Fertility, we aim to deliver the best female
                infertility treatment in Bangalore. We provide the best
                ovulation induction treatment for women, to increase the chances
                of conception. With our advanced fertility treatments. Our
                experts are here to help you create a miracle of joy in your
                life.
              </p>
              <p>
                Our goal is to provide the utmost care with the best suitable
                treatments like &nbsp;
                <a href="https://kangaroocarefertility.com/treatments/invitro-fertilization">
                  IVF treatment,&nbsp;
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intrauterine">
                  IUI,&nbsp;
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intracytoplasmic">
                  ICSI,&nbsp;
                </a>
                <a href="https://kangaroocarefertility.com/treatments/genetic-counselling-and-testing">
                  genetic counseling & testing,&nbsp;
                </a>
                <a href="https://kangaroocarefertility.com/treatments/varicocele">
                  varicocele treatment,&nbsp;
                </a>
                <a href="https://kangaroocarefertility.com/treatments/tesa-pesa">
                  TESA/PESA&nbsp;
                </a>
                for both female infertility and male infertility problems.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
