import React from "react";
import dinesh from "../assets/img/doctor/dinesh.jpg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function Dinesh() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName: "",
    Description: "",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className="banner-header text-center py-5">
            <h2 style={{ color: '#fff', position: "relative", top: "100px" }}>Dr. DIVYASREE D</h2>
        </div>
    </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img
                src={dinesh}
                className="card-img-top"
                alt="Dr. Divyashree D"
              />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>M.Sc - Assisted Reproduction & Embryology</p>
            <br />
            <p>
              Department : <span>SENIOR EMBRYOLOGIST</span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Bangalore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Toll-free number @ 1800-425-4500</p>
            <br />
          </div>

          <div className="col-md-7">
            <h1 className="text-blue">MR. DINESH KUMAR V</h1>
            <p>
              <strong>SENIOR EMBRYOLOGIST</strong>
            </p>
            <h3>PROFILE</h3>
            <p>
              Mr. Dinesh Kumar V is a dedicated Clinical Embryologist with
              expertise in Assisted Reproductive Technology (ART). With a strong
              foundation in embryology and molecular biology, he specializes in
              IVF, ICSI, and cryopreservation techniques. Passionate about
              patient care and laboratory ethics, he strives to enhance
              fertility treatments through advanced scientific approaches and
              quality control measures. His commitment to research and teaching
              further contributes to the field of reproductive medicine.
            </p>
            <h3>
              <strong>Qualification:</strong>
            </h3>

            <p>
              <ul>
                <li>
                  Master of Science (M.Sc - Assisted Reproduction & Embryology)
                  in Assisted Reproduction and Clinical Embryology – IIART-RC,
                  Mysore University,
                </li>
                <li>
                  Master of Science (M.Sc - Assisted Reproduction & Embryology)
                  in Molecular Biology – JSS University
                </li>
                <li>Bachelor of Science (B.Sc)</li>
              </ul>
            </p>
            <h3>
              <strong>Work Experience:</strong>
            </h3>
            <p>
              <ul>
                <li>SIRI CAREE IVF, Mysore – Clinical Embryologist</li>
                <li>Laboratory ethics and patient counseling</li>
                <li>Andrology techniques</li>
                <li>Quality control in ART procedures</li>
                <li>
                  Oocyte Pick-Up (OPU), Oocyte denudation, grading, and embryo
                  culture
                </li>
                <li>Single-step and sequential blast culture</li>
                <li>Vitrification and thawing of oocytes and embryos</li>
                <li>Aseptic techniques in IVF and ICSI</li>
                <li>
                  Oocyte identification, embryo transfer, documentation, and
                  record keeping
                </li>
                <li>Micromanipulator handling (ICSI – Narishege, RI)</li>
                <li>Teaching Faculty at IIART-RC College, Mysore</li>
              </ul>
            </p>
            <h3>
              <strong>Technical Skills:</strong>
            </h3>
            <p>
              <ul>
                <li>Embryology: IVF, ICSI, TESA, TESE, PESA, PESE</li>
                <li>
                  Andrology: Semen analysis, functional tests (WHO & Kruger),
                  sperm wash techniques, IUI preparation, cryopreservation, DNA
                  fragmentation test
                </li>
              </ul>
            </p>
            <h3>
              <strong>Instruments Handled:</strong>
            </h3>
            <p>
              <ul>
                <li>Electrophoresis unit (AGE & PAGE)</li>
                <li>Visible spectrophotometer</li>
                <li>High-speed refrigerated centrifuge</li>
                <li>Column chromatography</li>
                <li>IVF Work Station (ELITE Series, IVF TECH)</li>
                <li>Narishege IX 51, Stereo-Microscope</li>
              </ul>
            </p>
            <h3>
              <strong>Awards & Certifications:</strong>
            </h3>
            <p>
              <ul>
                <li>ACE Pre-Congress Workshop (2023-2024)</li>
                <li>SOUTH ISAR 2024</li>
                <li>ISAR Kerala 2024</li>
                <li>Embryo Milana 2023</li>
              </ul>
            </p>
            <h3>
              <strong>Research & Publications:</strong>
            </h3>
            <p>
              <ul>
                <li>
                  Quorum Sensing: A Molecular Cell Communication in Bacterial
                  Cells – Journal of Biomedical Science
                </li>
                <li>
                  Evaluation of Probiotic Strains of Lactobacillus Species from
                  Traditional Animal Sources and Alternative Plant Sources
                </li>
                <li>
                  Tinospora Cordifolia: The Antimicrobial Property of the Leaves
                  of Amruthaballi – Journal of Bacteriology & Mycology
                </li>
                <li>
                  The Antimicrobial Nature of Azadirachta Indica (Neem) in
                  Various Polar and Aqueous Extracts Against Escherichia Coli –
                  International Journal of Current Advanced Research
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
