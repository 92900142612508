import React from "react";
import "./camp.css";
import img12 from "../../assets/img/media/blogs/press1.jpeg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../../CustomTitle";

import camp1 from "../../assets/img/camps/camp-1.png";
import camp2 from "../../assets/img/camps/camp-2.jpg";
export default function Views360() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName: "Explore Fertility Solutions: Kangaroo Care Fertility 360° View",
    Description:
      "Embark on a holistic fertility journey with Kangaroo Care Fertility's 360° view, offering a complete perspective on fertility solutions.",
  };
  return (
    <>
      <CustomTitle
        href="https://kangaroocarefertility.com/media/press"
        customValue={helmetDescriptions}
      />
      {/* <div id="banner-logo">
        <p className="text-center" id="banner-logo-text">
          | PRESS |
        </p>
      </div>

      <h6
        className="offset-md-2 my-4"
        style={{ textAlign: "left", color: "black" }}
      >
        HOME &gt; MEDIA &gt; NEWS{" "}
      </h6>
      <br /> */}
      <br />
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Media
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            360 View
          </li>
        </ol>
      </nav>
      <h1 className="h1-top-text">Our Hospital Tour - Bangalore</h1>
      <div className="container" id="press-release">
        <iframe
          src="https://app.lapentor.com/sphere/kangaroocarefertility"
          frameborder="0"
          width="100%"
          height="400px"
          scrolling="no"
          allow="vr,gyroscope,accelerometer"
          allowfullscreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          oallowfullscreen="true"
          msallowfullscreen="true"
        ></iframe>
      </div>
      <h1 className="h1-top-text mt-5">Our Hospital Tour - Mysore</h1>
      <div className="container" id="press-release">
        <iframe
          src="https://app.lapentor.com/sphere/kcfrtltmys"
          frameborder="0"
          width="100%"
          height="400px"
          scrolling="no"
          allow="vr,gyroscope,accelerometer"
          allowfullscreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          oallowfullscreen="true"
          msallowfullscreen="true"
        ></iframe>
      </div>
      <h2 className="h1-top-text mt-5">Our Hospital Tour - Ramanagara</h2>
      <div className="container" id="press-release">
        <iframe
          src="https://app.lapentor.com/sphere/kcframnagar?scene=66c20f7dcdbb1a52450b8ef5"
          frameborder="0"
          width="100%"
          height="400px"
          scrolling="no"
          allow="vr,gyroscope,accelerometer"
          allowfullscreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          oallowfullscreen="true"
          msallowfullscreen="true"
        ></iframe>
      </div>
    </>
  );
}
{
  /* <div className="col-md-5">
<div className="card mb-4" id="news-card-kangaroo">
  <div className="row g-0">
    <div className="col-md-4" id="news-card-kangaroo-img">
      <img src={img12} alt="..." />
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <p className="card-text">
          Best Fertility Hospital in Mysore
        </p>
        <p className="card-text">
          <small className="text-muted">09 Oct 2021</small>
        </p>
      </div>
    </div>
  </div>
</div>
</div> */
}
