import React from "react";
import { useState } from "react";
//styles
import styles from "./pop.module.css";
import { useEffect } from "react";
export default function Popup() {
  const [show, setShow] = useState(false);

  const [form, setForm] = useState({
    fname: "",
    query: "",
    phone: "",
  });
  const handleChange = (e) => {
    let { name, value } = e.target;

    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (window.location.pathname === "/") {
      setTimeout(() => {
        setShow(true);
      }, 1000);
    }
  }, []);

  const handleActiveClick = () => {
    setShow(!show);
  };

  return (
    <section
      className={`${styles.pop_up_container} ${
        show && styles.pop_up_container_active
      }`}
    >
      <div className={styles.pop_up_bg}></div>
      <div
        className={`${styles.pop_up_width}   ${
          show && styles.pop_up_form_show
        }`}
      >
        <div className={styles.pop_up_title}>
          <h3>Get In Touch</h3>
          <span onClick={() => handleActiveClick()}>
            {" "}
            <i class="fas fa-times"></i>
          </span>
          {/* <AiFillCloseCircle onClick={() => handleActiveClick()} /> */}
          <p>{form.fname.length > 1 && `Hi ${form.fname}.`}</p>
        </div>

        <form
          className={`${styles.pop_up_form}`}
          method="POST"
          action="https://mail.digikit.in/forms/kangaroocarefertility/pop.php"
          id="pop_upform"
          name="pop_upform"
        >
          <div>
            <input
              type="text"
              name="fname"
              placeholder="Name"
              value={form.fname}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          {/* <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={form.email}
              onChange={(e) => handleChange(e)}
              required
            />
          </div> */}
          <div>
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={form.phone}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div>
            <input
              type="text"
              name="query"
              placeholder="Query"
              value={form.query}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div>
            <input type="submit" value="Submit" />
          </div>
        </form>
        {/* <p>Share the details to avail the offer! (T&C Apply)</p> */}
      </div>
    </section>
  );
}
