import React from "react";
import CustomTitle from "../../CustomTitle";
import "../Doctors.css";
import doc1 from "../../assets/img/doctor/dr-chandan-new.jpg";
import doc2 from "../../assets/img/doctor/dr-ramya-prakash.png";
import doc3 from "../../assets/img/doctor/dr-divyashree.png";
import doc4 from "../../assets/img/doctor/dr-4.jpg";
import doc04 from "../../assets/img/doctor/doc4-1.jpg";
import doc5 from "../../assets/img/doctor/dr-5.jpg";
import hema from "../../assets/img/doctor/dr-hema-h-r.png";
import dinesh from "../../assets/img/doctor/dinesh.jpg";
import satis from "../../assets/img/doctor/dr-srinath.jpg";
import vandana from "../../assets/img/doctor/dr-vandana.jpg";
import doc6 from "../../assets/img/doctor/shankar-singh.jpg";
import doc7 from "../../assets/img/doctor/tarun-javeli.jpeg";
import anjana from "../../assets/img/doctor/anjana.jpg";
import sivachandra from "../../assets/img/doctor/sivachandra.jpg";
import { useLocation, Link } from "react-router-dom";
import Bangalore from "./Schemas/Bangalore";
export default function Doctors() {
  const helmetDescriptions = {
    titleName:
      "Best Fertility and IVF Specialists in Bangalore – Expert Doctors",
    Description:
      "Find the best fertility and IVF specialists in Bangalore. Expert doctors offering advanced treatments for infertility, IUI, IVF, and reproductive health care.",
  };

  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <CustomTitle
        href="https://kangaroocarefertility.com/doctors/fertility-specialist-in-bangalore"
        customValue={helmetDescriptions}
      />
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Best Fertility Specialist in Bangalore
          </li>
        </ol>
      </nav>
      {/* ------------------banner close content start-------------- */}
      <h1 className="text-center mb-2" style={{ color: "#7e52a0" }}>
        Best Fertility Specialist in Vijayanagar Bangalore
      </h1>
      {/* <p style={{ width: "80%", margin: "0 auto", textAlign: "center" }}>
        At Kangaroo Care Fertility, we follow transparent and ethical practices
        that are found on the basis of mutual respect and trust. Our best IVF
        specialists in Vijayanagar Bangalore work towards providing the
        best-personalized care for our patients. <br />
        <br />
      </p> */}
      <div className="container-fluid bg-danger p-0 mt-0">
        <div className="left-side-text row pt-sm-2 p-0">
          <div className=" col-md-4 col-sm-12">
            <div className="text py-5">
              <h2 className="text-center text-white">Fertility and IVF</h2>
            </div>
          </div>

          <div className="col-md-8 col-sm-12" id="doctor-page-card">
            <div className="content-holder bg-white py-sm-5 px-3">
              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc0} alt="..." />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">Dr. SHEKAR SUBBAIAH</h5>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT-NEONATOLOGIST
                        </small>
                      </p>
                      <p className="card-text">
                        MBBS, MD (Paediatrics), Masters in Medical administration (NSW University, Australia)
                      </p>
                      <Link to="/doctor/shekar" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc3} alt="Dr. Divyashree D" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">DR. DIVYASREE D</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT - FERTILITY SPECIALIST
                        </small>
                      </p>
                      <p className="card-text">
                        MBBS, MS (OBG), FRM
                        <br />
                        Fellowship in Reproductive Medicine
                      </p>
                      <Link to="/doctor/divyasree" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc2} alt="Dr. Ramya  Prakash" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">DR. RAMYA PRAKASH</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT - FERTILITY SPECIALIST
                        </small>
                      </p>
                      <p className="card-text">
                        MBBS, DGO (OBG), FRM
                        <br />
                        Fellowship in Reproductive Medicine
                      </p>
                      <Link to="/doctor/ramya" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc4} alt="Dr. Srinivas BV" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title"> Dr. SRINIVAS BV</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT-ANDROLOGIST & UROLOGIST
                        </small>
                      </p>
                      <p className="card-text">MBBS, MS DNB- Urology</p>
                      <Link to="/doctor/srinivas" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc7} alt="Dr. Tarun" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">DR. TARUN JAVALI </h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT - ANDROLOGIST & UROLOGIST
                        </small>
                      </p>
                      <p className="card-text">
                        MBBS , MS (General Surgery), MCh (Urology)
                      </p>
                      <Link to="/doctor/tarun-javali" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc1} alt="Dr. Chandan" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">DR. CHANDAN</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT - INHOUSE EMBRYOLOGIST
                        </small>
                      </p>
                      <p className="card-text">MSc, PHD, Embcol PGD </p>
                      <Link to="/doctor/chandan" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}

              {/*  */}

              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={satis} alt="Dr. Satish Babu" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">DR. SRINATH A</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT - GENERAL PHYSICIAN
                        </small>
                      </p>
                      <p className="card-text">
                        {/* MD - General Medicine, Endocrinology And Diabetology,
                        MBBS - Endocrinology And Diabetology, SCE Endocrinology
                        and Diabetes, MRCP (UK) */}
                        MBBS, MD (General Medicine), DM (Endocrinology),
                        MRCP(UK)
                      </p>
                      <Link to="/doctor/srinath" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={anjana} alt="Dr. ANJANA CHERAL" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">DR. ANJANA CHERAL</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT - GENERAL PHYSICIAN
                        </small>
                      </p>
                      <p className="card-text">MBBS, MD (General Medicine)</p>
                      <Link to="/doctor/anjana" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={hema} alt="Dr. Hema H R" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">DR. HEMA H R</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT - FAMILY PHYSICIAN
                        </small>
                      </p>
                      <p className="card-text">
                        MBBS, MD (Family Medicine), CCEBDM, CCGDM, CCMH
                      </p>
                      <Link to="/doctor/hema" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={dinesh} alt="Dinesh" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">MR. DINESH KUMAR V</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          SENIOR EMBRYOLOGIST
                        </small>
                      </p>
                      <p className="card-text">M.SC</p>
                      <Link to="/doctor/dinesh" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/*  */}
              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc6} alt="Dr. Chandan" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Mr. SHANKAR SINGH</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">EMBRYOLOGIST</small>
                      </p>
                      <p className="card-text">
                        MS.c Zoology, Diploma in Clinical Embryology{" "}
                      </p>
                      <Link to="/doctor/shankar-singh" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={sivachandra} alt="Dr. Divyashree D" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Ms . SIVACHANDRA M</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          JUNIOR EMBRYOLOGIST
                        </small>
                      </p>
                      <p className="card-text">
                        Master of Biotechnology, BSC Biotechnology
                      </p>
                      <Link to="/doctor/dr-sivachandra" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc5} alt="Dr. NIBY J Elackatt" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Dr. NIBY J ELACKATT</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT-GENETICS COUNSELOR
                        </small>
                      </p>
                      <p className="card-text">
                        MS in Genetic Counselling from Griffith University,
                        Australia.
                      </p>
                      <Link
                        to="/doctor/niby-j-elackatt"
                        className="havingbtnme"
                      >
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc04} alt="Dr. Pramodh" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Dr. PRAMODH</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT-LIFESTYLE & ADDICTION MEDICINE
                        </small>
                      </p>
                      <p className="card-text">MBBS, DFM, DHA, CCEBDM, MLSM</p>
                      <Link to="/doctor/pramodh" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={vandana} alt="Dr. Kavya Krishnappa" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">VANDANA</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT - INHOUSE CLINICAL EMBRYOLOGIST & MOLECULAR
                          BIOLOGIST
                        </small>
                      </p>
                      <p className="card-text">MS (Biotechnology), PGDCE</p>
                      <Link to="/doctor/vandana" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              {/* <h2 className="heading2">
                {" "}
                Best Fertility Specialist in Vijayanagar Bangalore
              </h2> */}
              {/* <p>
                Kangaroo Care Fertility comprises the best fertility doctors in
                Vijayanagar Bangalore providing advanced fertility treatments
                such as &nbsp;
                <Link to="/treatments/invitro-fertilization">IVF, </Link>
                <Link to="/treatments/intrauterine">IUI, </Link>
                <Link to="/treatments/intracytoplasmic">ICSI, </Link>
                etc for female and male infertility problems. We believe that
                everyone deserves a chance and our aim is to provide the best
                results. Our best fertility doctors in Bangalore try to foster
                an environment where queries are encouraged from the patients.
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <Bangalore />
    </div>
  );
}
