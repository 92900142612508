import React from "react";
import satis from "../assets/img/doctor/dr-srinath.jpg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function Srinath() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName: "Dr. SRINATH A | General Physician  | Kangaroo Care Fertility",
    Description:
      "Dr. Srinath A: Your trusted MD in General Medicine, Endocrinology, and Diabetology. Experience personalized healthcare solutions for a better life.",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={satis} className="card-img-top" alt="Dr. Srinath" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>MD - General Medicine, Endocrinology And Diabetology,</p>
            <p>
              MBBS - Endocrinology And Diabetology, SCE Endocrinology and
              Diabetes, MRCP (UK)
            </p>
            <p>
              Department : <span>Infertility And Ivf</span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Bangalore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Toll-free number @ 1800-425-4500</p>
            <br />
          </div>

          <div className="col-md-7">
            <h1 className="text-blue">Dr. SRINATH A</h1>

            <p>
              Dr. Srinath A has an experience of 31 years in this field. Dr.
              Srinath A practices at BGS Gleneagles Global Hospital in Kengeri,
              Bangalore, Bangalore Diagnostic Lab And Specialty Centre in
              Rajajinagar, Bangalore and Atreum Speciality Hospital in
              Rajarajeshwarinagar, Bangalore. He completed MBBS from Devaraj URS
              Medical College - Kolar in 1993, MRCP (UK) from Royal College Of
              Physician, London in 2007 and MD - General Medicine from JJMMC
              Davangere RGUHS Bangalore in 1998.
            </p>
            <p>
              He is a member of Indian Medical Association (IMA). Some of the
              services provided by the doctor are: Hypertension
              Treatment,Thyroid Swelling,Diabetic Diet Counseling,Parathyroid
              diseases and Lactation Counseling etc.
            </p>

            <h3>QUALIFICATION</h3>
            <p>
              MBBS - Endocrinology And Diabetology, MD - General Medicine,
              Endocrinology And Diabetology, SCE Endocrinology and Diabetes,
              MRCP (UK)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
