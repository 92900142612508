export const RedirectionArray = [
  {
    link: ["/doctor/srinivas"],
    redirect: "/",
  },
  {
    link: ["/treatments/best-ivf-hospital-in-bangalore"],
    redirect: "/treatments/invitro-fertilization",
  },
  {
    link: ["/doctor/drseema"],
    redirect: "/",
  },
  {
    link: [
      "/about-us/https:/kangaroocare.spartahms.com/OnlineAppointment/Index",
    ],
    redirect: "/",
  },
  {
    link: ["/doctor/kavya"],
    redirect: "/doctors/bangalore",
  },
  {
    link: [
      "/treatments/invitro-fertilization/https:/kangaroocare.spartahms.com/OnlineAppointment/Index",
    ],
    redirect: "/treatments/invitro-fertilization",
  },
  {
    link: [
      "/doctor/niby-j-elackatt/https:/kangaroocare.spartahms.com/OnlineAppointment/Index",
    ],
    redirect: "/",
  },
  {
    link: ["/about-us/https:/www.kangaroocareindia.com/"],
    redirect: "/",
  },
  {
    link: ["/treatments"],
    redirect: "/fertility-treatments",
  },
  {
    link: [
      "/treatments/invitro-fertilization/https://kangaroocare.spartahms.com/OnlineAppointment/Index",
    ],
    redirect: "/",
  },
  {
    link: ["/doctor/doctors"],
    redirect: "/",
  },
  {
    link: [
      "/about-us/https://kangaroocare.spartahms.com/OnlineAppointment/Index",
    ],
    redirect: "/",
  },
  {
    link: [
      "/media/blogs/a-step-by-step-overview-of-intracytoplasmic-sperm-injection/Testicular%20Sperm%20Aspiration%20(TESA)",
    ],
    redirect:
      "/media/blogs/a-step-by-step-overview-of-intracytoplasmic-sperm-injection/35",
  },
  {
    link: [
      "/media/blogs/about-14-Days-after-Ovulation/Kangaroo%20Care%20Fertility%20Hospitals",
    ],
    redirect: "/media/blogs/about-14-Days-after-Ovulation/34",
  },
  {
    link: [
      "/media/blogs/about-14-Days-after-Ovulation/Kangaroo%20Care%20Fertility%20Hospitals",
    ],
    redirect: "/media/blogs/about-14-Days-after-Ovulation/34",
  },
  {
    link: ["/treatments/vericocele"],
    redirect: "/treatments/varicocele",
  },
  {
    link: ["/doctor/satis"],
    redirect: "/doctors/bangalore",
  },
  {
    link: ["/media/blogs/media/blogs/andrology1/1"],
    redirect: "/media/blogs/andrology1/1",
  },
  {
    link: ["/media/blogs/media/blogs/fertility-health-tips/39"],
    redirect: "/media/blogs/fertility-health-tips/39",
  },
  {
    link: [
      "/media/blogs/media/blogs/how-to-improve-your-chances-of-getting-pregnant-after-ivf/25",
    ],
    redirect:
      "/media/blogs/how-to-improve-your-chances-of-getting-pregnant-after-ivf/25",
  },
  {
    link: [
      "/media/blogs/media/blogs/the-five-best-ways-to-make-your-periods-eco-friendly/19",
    ],
    redirect:
      "/media/blogs/the-five-best-ways-to-make-your-periods-eco-friendly/19",
  },
  {
    link: [
      "/media/blogs/media/blogs/effect-of-weight-on-fertility-and-pregnancy/10",
    ],
    redirect: "/media/blogs",
  },
  {
    link: ["/media/blogs/media/blogs/andrology1/1"],
    redirect: "/media/blogs",
  },
  {
    link: [
      "/media/blogs/media/blogs/what-are-the-treatments-for-secondary-infertility-in-men/30",
    ],
    redirect:
      "/media/blogs/what-are-the-treatments-for-secondary-infertility-in-men/30",
  },
  {
    link: ["/media/blogs/media/blogs/embryo-freezing-what-you-need-to-know/21"],
    redirect: "/media/blogs/embryo-freezing-what-you-need-to-know/21",
  },
  {
    link: [
      "/media/blogs/media/blogs/how-do-i-track-the-different-stages-of-ivf/26",
    ],
    redirect: "/media/blogs/how-do-i-track-the-different-stages-of-ivf/26",
  },
  {
    link: ["/media/blogs/media/blogs/ivf-pregnancy-test/28"],
    redirect: "/media/blogs/ivf-pregnancy-test/28",
  },
  {
    link: [
      "/media/blogs/media/blogs/common-problems-that-couples-face-during-ivf-treatment/24",
    ],
    redirect:
      "/media/blogs/common-problems-that-couples-face-during-ivf-treatment/24",
  },
  {
    link: [
      "/media/blogs/media/blogs/treatment-options-for-male-infertility/20",
    ],
    redirect: "/media/blogs/treatment-options-for-male-infertility/20",
  },
  {
    link: [
      "/media/blogs/media/blogs/how-to-improve-cervical-mucus-fertility/22",
    ],
    redirect: "/media/blogs/how-to-improve-cervical-mucus-fertility/22",
  },
  {
    link: ["/media/blogs/media/blogs/hypothyroidism-pregnancy/8"],
    redirect: "/media/blogs/hypothyroidism-pregnancy/8",
  },
  {
    link: [
      "/media/blogs/media/blogs/endometriosis-symptoms-causes-and-treatment/17",
    ],
    redirect: "/media/blogs/endometriosis-symptoms-causes-and-treatment/17",
  },
  {
    link: ["/media/blogs/media/blogs/myths-about-infertility/38"],
    redirect: "/media/blogs/myths-about-infertility/38",
  },
  {
    link: ["/media/blogs/media/blogs/common-misconceptions-about-fertility/4"],
    redirect: "/media/blogs/common-misconceptions-about-fertility/4",
  },
  {
    link: ["/media/blogs/media/blogs/best-food-habits-to-enhance-fertility/6"],
    redi: "/media/blogs/best-food-habits-to-enhance-fertility/6",
  },
  {
    link: ["/media/blogs/media/blogs/poor-ovarion-reserve/3"],
    redirect: "/media/blogs/poor-ovarion-reserve/3",
  },
  {
    link: [
      "/media/blogs/media/blogs/what-is-genetic-counselling-and-testing/32",
    ],
    redirect:
      "/media/blogs/what-is-genetic-counselling-and-testing/32#:~:text=Genetic%20counseling%20is%20a%20process,potential%20risks%20associated%20with%20them.",
  },
  {
    link: [
      "/media/blogs/media/blogs/a-step-by-step-overview-of-the-ivf-process/18",
    ],
    redirect: "/media/blogs/a-step-by-step-overview-of-the-ivf-process/18",
  },
  {
    link: [
      "/media/blogs/media/blogs/the-five-best-ways-to-make-your-periods-eco-friendly/19",
    ],
    redirect:
      "/media/blogs/the-five-best-ways-to-make-your-periods-eco-friendly/19",
  },
  {
    link: ["/media/blogs/media/blogs/who-need-to-go-for-ivf-treatment/15"],
    redirect: "/media/blogs/who-need-to-go-for-ivf-treatment/15",
  },
  {
    link: ["/media/blogs/media/blogs/age-and-fertility/7"],
    redirect: "/media/blogs/age-and-fertility/7",
  },
  {
    link: [
      "/media/blogs/media/blogs/what-is-the-difference-between-iui-and-ivf/31",
    ],
    redirect:
      "/media/blogs/what-is-the-difference-between-iui-and-ivf/31#:~:text=While%20IUI%20is%20a%20less,couples%20facing%20significant%20fertility%20challenges.",
  },
  {
    link: ["/media/blogs/media/blogs/polycystic-ovary-syndrome/2"],
    redirect: "/media/blogs/media/blogs/polycystic-ovary-syndrome/2",
  },
  {
    link: ["/media/blogs/media/blogs/process-of-ovulation-induction/33"],
    redirect: "/media/blogs/process-of-ovulation-induction/33",
  },
  {
    link: ["/media/blogs/media/blogs/about-14-Days-after-Ovulation/34"],
    redirect:
      "/media/blogs/about-14-Days-after-Ovulation/34#:~:text=The%20post-ovulatory%20(luteal),menstruation%20or%20a%20potential%20pregnancy.",
  },
  {
    link: [
      "/media/blogs/media/blogs/basics-of-fertility-is-male-infertility-treatable/23",
    ],
    redirect:
      "/media/blogs/basics-of-fertility-is-male-infertility-treatable/23",
  },
  {
    link: [
      "/media/blogs/media/blogs/a-step-by-step-overview-of-intracytoplasmic-sperm-injection/35",
    ],
    redirect:
      "/media/blogs/a-step-by-step-overview-of-intracytoplasmic-sperm-injection/35",
  },
  {
    link: ["/media/blogs/media/blogs/what-are-the-benefits-of-iui/37"],
    redirect: "/media/blogs/what-are-the-benefits-of-iui/37",
  },
  {
    link: ["/media/blogs/media/blogs/how-does-pcos-affect-fertility/13"],
    redirect: "/media/blogs/how-does-pcos-affect-fertility/13",
  },
  {
    link: ["/media/blogs/media/blogs/cancer-and-fertility-preservation/14"],
    redirect: "/media/blogs/cancer-and-fertility-preservation/14",
  },
  {
    link: ["/media/blogs/media/blogs/causes-of-infertility-in-male/9"],
    redirect: "/media/blogs/causes-of-infertility-in-male/9",
  },
  {
    link: ["/media/blogs/media/blogs/retrieve-eggs-ivf-process/29"],
    redirect: "/media/blogs/retrieve-eggs-ivf-process/29",
  },
  {
    link: [
      "/media/blogs/media/blogs/fibroids-on-female-fertility-and-pregnancy/16",
    ],
    redirect: "/media/blogs/fibroids-on-female-fertility-and-pregnancy/16",
  },
  {
    link: [
      "/media/blogs/media/blogs/all-you-need-to-know-about-ivf-icsi-treatment/11",
    ],
    redirect:
      "/media/blogs/all-you-need-to-know-about-ivf-icsi-treatment/11#:~:text=These%20eggs%20are%20then%20fertilized,a%20process%20called%20Embryo%20Transfer.",
  },
  {
    link: ["/media/blogs/media/blogs/causes-of-female-infertility/12"],
    redirect: "/media/blogs/causes-of-female-infertility/12",
  },
  {
    link: ["/media/blogs/media/blogs/tips-to-boost-your-fertility/5"],
    redirect: "/media/blogs/tips-to-boost-your-fertility/5",
  },
  {
    link: ["/media/blogs/media/blogs/what-are-tests-for-infertility/27"],
    redirect: "/media/blogs/what-are-tests-for-infertility/27",
  },
  {
    link: [
      "/media/blogs/media/blogs/what-are-the-most-common-fertility-issues-in-men/36",
    ],
    redirect:
      "/media/blogs/what-are-the-most-common-fertility-issues-in-men/36",
  },
  {
    link: ["/doctor/divyasree/"],
    redirect: "/doctor/divyasree",
  },
  {
    link: [
      "/media/blogs/ivf-and-gender-selection-options-for-family-planning/83",
    ],
    redirect: "/media/blogs",
  },
];
