import React from "react";
import CustomTitle from "../../CustomTitle";
import "../Doctors.css";
import doc1 from "../../assets/img/doctor/dr-1.jpg";
import doc2 from "../../assets/img/doctor/dr-ramya-prakash.png";
import doc3 from "../../assets/img/doctor/dr-seema.png";
import hemalatha from "../../assets/img/doctor/hemalatha-ramanagar.jpg";
import YeshodaMahesh from "../../assets/img/doctor/dr-yeshodha-s-mahesh.jpg";
import KUSUMA from "../../assets/img/doctor/dr-kusuma.jpg";
import sivachandra from "../../assets/img/doctor/sivachandra.jpg";
import doc from "../../assets/img/doctor/testDoctor.png";
import pavithra from "../../assets/img/doctor/pavithara-h.jpeg";
import pallavi from "../../assets/img/doctor/pallavi1.jpg";
import rashmi from "../../assets/img/doctor/dr-rashmi-new.jpg";

import doc4 from "../../assets/img/doctor/dr-4.jpg";
import doc04 from "../../assets/img/doctor/doc4-1.jpg";
import doc5 from "../../assets/img/doctor/dr-5.jpg";
import hema from "../../assets/img/doctor/hema.jpg";
import satis from "../../assets/img/doctor/sathish.jpg";
import vandana from "../../assets/img/doctor/dr-vandana.jpg";
import { useLocation, Link } from "react-router-dom";
import Mysore from "./Schemas/Mysore";
export default function Ramanagara() {
  const helmetDescriptions = {
    titleName:
      "Best Fertility & IVF Specialists in Ramanagara – Expert Doctors",
    Description:
      "Find the best fertility and IVF specialists in Ramanagara. Expert doctors offering advanced treatments for infertility, IUI, IVF, and reproductive health care.",
  };

  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <CustomTitle
        href="https://kangaroocarefertility.com/doctors/fertility-specialist-in-bangalore"
        customValue={helmetDescriptions}
      />
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Best Fertility Specialist in Ramanagara
          </li>
        </ol>
      </nav>
      {/* ------------------banner close content start-------------- */}
      <h1 className="text-center mb-2" style={{ color: "#7e52a0" }}>
        Best Fertility Specialist in Ramanagara
      </h1>
      {/* <p style={{ width: "80%", margin: "0 auto", textAlign: "center" }}>
        At Kangaroo Care Fertility, we follow transparent and ethical practices
        that are found on the basis of mutual respect and trust. Our best IVF
        specialists in Mysore work towards providing the best-personalized care
        for our patients. <br />
        <br />
      </p> */}
      <div className="container-fluid bg-danger p-0 mt-0">
        <div className="left-side-text row pt-sm-2 p-0">
          <div className=" col-md-4 col-sm-12">
            <div className="text py-5">
              <h2 className="text-center text-white">Fertility and IVF</h2>
            </div>
          </div>

          <div className="col-md-8 col-sm-12" id="doctor-page-card">
            <div className="content-holder bg-white py-sm-5 px-3">
              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc0} alt="..." />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">Dr. SHEKAR SUBBAIAH</h5>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT-NEONATOLOGIST
                        </small>
                      </p>
                      <p className="card-text">
                        MBBS, MD (Paediatrics), Masters in Medical administration (NSW University, Australia)
                      </p>
                      <Link to="/doctor/shekar" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc3} alt="Dr. Divyashree D" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Dr Seema S. J</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                        OBSTETRICS AND INFERTILITY
                        </small>
                      </p>
                      <p className="card-text">
                      M.B.B.S
                        <br />
                        Fellowship in Reproductive Medicine
                      </p>
                      <Link to="/doctor/drseema" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={hemalatha} alt="Dr. Divyashree D" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">HEMALATHA N</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">EMBRYOLOGIST</small>
                      </p>
                      <p className="card-text">
                        MSC BIOLOGICAL SCIENCE & DIPLOMA IN CEART EMBRYOLOGIST
                      </p>
                      <Link to="/doctor/hemalatha" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={sivachandra} alt="Dr. Divyashree D" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Dr . SIVACHANDRA M</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          JUNIOR EMBRYOLOGIST
                        </small>
                      </p>
                      <p className="card-text">
                        Master of Biotechnology, BSC Biotechnology
                      </p>
                      <Link to="/doctor/dr-sivachandra" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={pallavi} alt="Dr. PALLAVI R GANGATKAR" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">DR. PALLAVI R GANGATKAR</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT - FERTILITY SPECIALIST
                        </small>
                      </p>
                      <p className="card-text">MBBS, MS (OBG),DNB, FRM</p>
                      <Link
                        to="/doctor/pallavi-r-gangatkar"
                        className="havingbtnme"
                      >
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={YeshodaMahesh} alt="DR. YESHODA MAHESH" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">DR. YESHODA MAHESH</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT - GENERAL PHYSICIAN
                        </small>
                      </p>
                      <p className="card-text">MBBS, MD, FAGE</p>
                      <Link
                        to="/doctor/yeshoda-s-mahesh"
                        className="havingbtnme"
                      >
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={KUSUMA} alt="DR. KUSUMA " />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">DR. KUSUMA </h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT - DIABETOLOGIST
                        </small>
                      </p>
                      <p className="card-text">
                        MBBS & FELLOWSHIP IN DIABETOLOGY (FID)
                      </p>
                      <Link to="/doctor/dr-kusuma" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={pavithra} alt="Ms. PAVITHRA H" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">MS. PAVITHRA H</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          JUNIOR EMBRYOLOGIST
                        </small>
                      </p>
                      <p className="card-text">
                        DMLT (Diploma in Medical Laboratory Technology){" "}
                      </p>
                      <Link to="/doctor/pavithra-h" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc3} alt="Dr. Divyashree D" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Dr. DIVYASREE D</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT-FERTILITY SPECIALIST
                        </small>
                      </p>
                      <p className="card-text">
                        MBBS, MS (OBG), FRM
                        <br />
                        Fellowship in Reproductive Medicine
                      </p>
                      <Link to="/doctor/divyasree" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc2} alt="Dr. Ramya  Prakash" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">DR. RAMYA PRAKASH</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT-FERTILITY SPECIALIST
                        </small>
                      </p>
                      <p className="card-text">
                        M.B.B.S, DGO, FRM,
                        <br />
                        CIMPE Fellowship in Reproductive Medicine
                      </p>
                      <Link to="/doctor/ramya" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc4} alt="Dr. Srinivas BV" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title"> Dr. SRINIVAS BV</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT-ANDROLOGIST & UROLOGIST
                        </small>
                      </p>
                      <p className="card-text">MBBS, MS DNB- Urology</p>
                      <Link to="/doctor/srinivas" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc1} alt="Dr. Chandan" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Dr. CHANDAN</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT - INHOUSE EMBRYOLOGIST
                        </small>
                      </p>
                      <p className="card-text">MSc, PHD, Embcol PGD </p>
                      <Link to="/doctor/chandan" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={satis} alt="Dr. Satish Babu" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Dr. SATISH BABU</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT-DIABETES AND ENDOCRINOLOGY
                        </small>
                      </p>
                      <p className="card-text"> MBBS | MD (General Medicine)</p>
                      <Link to="/doctor/satis" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={hema} alt="Dr. Hema H R" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Dr. HEMA H R</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT-FAMILY PHYSICIAN
                        </small>
                      </p>
                      <p className="card-text">
                        MBBS, M.MED (Family Medicine), CCEBDM, CCGDM, CCMH
                      </p>
                      <Link to="/doctor/hema" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc5} alt="Dr. NIBY J Elackatt" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Dr. NIBY J ELACKATT</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT-GENETICS COUNSELOR
                        </small>
                      </p>
                      <p className="card-text">
                        MS in Genetic Counselling from Griffith University,
                        Australia.
                      </p>
                      <Link
                        to="/doctor/niby-j-elackatt"
                        className="havingbtnme"
                      >
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* 
              <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={doc04} alt="Dr. Pramodh" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">Dr. PRAMODH</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT-LIFESTYLE & ADDICTION MEDICINE
                        </small>
                      </p>
                      <p className="card-text">MBBS, DFM, DHA, CCEBDM, MLSM</p>
                      <Link to="/doctor/pramodh" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="card mx-sm-5 mb-5">
                <div className="row g-0">
                  <div className="col-md-3 align-self-center ps-sm-4">
                    <img src={vandana} alt="Dr. Kavya Krishnappa" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h3 className="card-title">VANDANA</h3>
                      <p className="card-text">
                        <small className="font-weight-bold">
                          CONSULTANT - INHOUSE CLINICAL EMBRYOLOGIST & MOLECULAR
                          BIOLOGIST
                        </small>
                      </p>
                      <p className="card-text">MS (Biotechnology), PGDCE</p>
                      <Link to="/doctor/vandana" className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              {/* <h2 className="heading2">
                {" "}
                Best Fertility Specialist in Ramanagara
              </h2> */}
              {/* <p>
                Kangaroo Care Fertility comprises the best fertility doctors in
                Vijayanagar Bangalore providing advanced fertility treatments
                such as &nbsp;
                <Link to="/treatments/invitro-fertilization">IVF, </Link>
                <Link to="/treatments/intrauterine">IUI, </Link>
                <Link to="/treatments/intracytoplasmic">ICSI, </Link>
                etc for female and male infertility problems. We believe that
                everyone deserves a chance and our aim is to provide the best
                results. Our best fertility doctors in Ramanagara try to foster
                an environment where queries are encouraged from the patients.
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <Mysore />
    </div>
  );
}
