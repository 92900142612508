import React from "react";
import kcf_white_logo from "./kcf_white_logo.webp";
import "./Footer.css";
import { useLocation, Link } from "react-router-dom";
import locationData from "../json/locations.json";
// import AOS from "aos";
// import "aos/dist/aos.css";
function Footer() {
  // React.useEffect(() => {
  //   AOS.init();
  //   AOS.refresh();
  // }, []);
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <footer className="container-fluid bg-secondary">
      <div className="row text-white">
        <div
          className="col-md-5 col-sm-12 order-1 order-sm-0 pt-4 "
          style={{ backgroundColor: "#7E52A0" }}
        >
          <div className="footer-logo footer_logo_container">
            <Link title="Kangaroo Care - Women & Child Hospital" to={`#}`}>
              <img
                className="footer-logo pb-4"
                src={kcf_white_logo}
                alt="Kangaroo Care Fertility Logo in White"
              />
            </Link>
            {/* <p>Kangaroo Care Fertility: Nurturing Dreams into Reality</p> */}
            <p>
              At Kangaroo Care Fertility, we offer personalized and affordable
              fertility care. Our skilled staff of industry experts will guide
              and support you throughout your conception experience. We
              understand that this process can be emotionally and physically
              challenging, and hence, we're here to stand behind you and help
              you through your fertility journey with integrity and compassion.
              Trusted by many families, our goal is to make your parenthood
              dreams a reality; because your journey matters to us!
            </p>
            <p>"Where hope meets happiness."</p>

            <p style={{ marginBottom: 0 }}>
              Trusted by <strong>many </strong> families
            </p>
          </div>
        </div>
        <div
          className="col-md-7 col-sm-12 order-0 order-sm-1 pt-4"
          style={{ backgroundColor: "#9b5ba4" }}
        >
          <div className="row footer_address">
            <div className="col-md-6 mb-3">
              <div className="services-link">
                <h3 className="heading">Services</h3>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/treatments/fertility-testing-for-men">
                      Male Fertility
                    </Link>
                  </li>
                  <li>
                    <Link to="/treatments/fertility-testing-for-women">
                      Female Fertility
                    </Link>
                  </li>
                  <li>
                    <Link to="/treatments/ovulation-induction">
                      Ovulation Induction
                    </Link>
                  </li>

                  <li>
                    <Link to="/treatments/intrauterine">
                      Intrauterine Insemination (IUI)
                    </Link>
                  </li>
                  <li>
                    <Link to="/treatments/invitro-fertilization">
                      In Vitro Fertilisation (IVF)
                    </Link>
                  </li>
                  <li>
                    <Link to="/treatments/intracytoplasmic">
                      Intracytoplasmic Sperm Injection (ICSI)
                    </Link>
                  </li>
                  <li>
                    <Link to="/treatments/genetic-counselling-and-testing">
                      Genetic Counselling and Testing
                    </Link>
                  </li>
                  <li>
                    <Link to="/treatments/varicocele">Andrology</Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-6 mb-3">
                <div className="follow-link">
                  <h3 className="heading">Follow Us On</h3>
                  <ul className="list-icons">
                    <li>
                      <a href="https://www.linkedin.com/company/kangaroocare/ ">
                        <i class="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/kangaroocarehospital/">
                        <i class="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/kangaroocareindia/">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>

                    <li>
                      <a href="https://x.com/KangarooCare3">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="row d-flex justify-content-between ">
                <h3 className="heading">Our Centers</h3>
                <div className="col-md-12 contact-info-link">
                  <ul className="footer_locations">
                    {locationData.map((item) => {
                      return (
                        <li key={item.id} className="footer_center">
                          <Link to={item.link}>
                            {" "}
                            <img
                              src={`/images/locations/${item.cityIcon}`}
                              className="wFilter"
                            />
                            <h3>{item.title}</h3>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                  <br />

                  <p>
                    <a href="mailto:fertilitymarketing@kangaroocareindia.com">
                      <i className="fas fa-envelope-open me-2"></i>
                      fertilitymarketing@kangaroocareindia.com
                    </a>
                  </p>
                  <div className="contact-number">
                    <i className="fas fa-tty me-2"></i>
                    <a href="tel:1800-425-4500"> Toll Free - 1800-425-4500</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-12 text-center text-white">
          © Managed & Maintained by <a href="http://digikit.in"> DIGIKIT</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
