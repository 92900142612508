import React from "react";
import CustomTitle from "../CustomTitle";
import "./FertilityPreservation.css";
import { useLocation, Link } from "react-router-dom";
export default function Services() {
  const helmetDescriptions = {
    titleName:
      "Fertility Preservation Center in Bangalore | Top Fertility Hospital",
    Description:
      "Kangaroo Care Fertility is the best choice for fertility preservation. We have well equiped labs for embryo freezing for later use. Contact us for more details.",
  };
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const fertilityPreserSchema = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How do you preserve fertility ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Preserving fertility involves, retrieving and freezing human reproductive tissue for later use in IUI or IVF/ICSI. We can cryopreserve eggs, sperm, embryo, ovarian tissue."
      }
    },{
      "@type": "Question",
      "name": "Can sperms be preserved and for how long ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Sperms can be cryopreserved in men who may not be available for treatment, in cancer patients,etc. Sperms can be cryopreserved for several years."
      }
    },{
      "@type": "Question",
      "name": "Who can freeze their eggs ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Eggs are frozen in women who want to plan their pregnancy at a later date. Both married and unmarried women can store their eggs."
      }
    },{
      "@type": "Question",
      "name": "How long is the fertility preservation in women?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The process takes around 2 weeks."
      }
    }]
  }
  </script>


  `;
  return (
    <div>
      <CustomTitle
        href="https://kangaroocarefertility.com/treatments/fertility-preservation"
        customValue={helmetDescriptions}
      />
      <iframe
        srcDoc={fertilityPreserSchema}
        title="schema"
        width="0"
        height="0"
      />
      <div className="main-content container mt-2">
        <nav aria-label="breadcrumb" className="mb-5">
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/treatments/fertility-preservation">Treatments</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Fertility Preservation
            </li>
          </ol>
        </nav>
      </div>

      {/* -------main content------------ */}

      {/* -----------------------------what is portion -------------------- */}
      <div className="container-fluid service my-5">
        <div className="row justify-content-center">
          <div className="col-md-6 mx-2 d-none d-sm-block">
            <div className="mb-2" id="service-left-img"></div>
          </div>
          <div className="col-md-5 mb-2 mx-2" id="service-content-card">
            <h1 className="my-4 text-center text-blue">
              Fertility Preservation (FP)
            </h1>
            <hr />
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>What is Fertility preservation?</strong>
            </p>
            <p>
              Fertility preservation is the term used for interventions and
              procedures aiming at preserving the chance of having a baby when
              the fertility may be damaged by certain medical conditions or
              treatment for the same.
            </p>
          </div>
        </div>

        {/* -----------------------section2 content process--------------------- */}

        <div className="row justify-content-center mt-5">
          <div className="col-md-8 mx-2" id="service-content2-card">
            <div className="mb-2 p-4">
              <h2 className="text-center text-blue"> When is it advised</h2>
              <br />
              <p>
                <i className="fas fa-check-circle me-2"></i>
                In men, women or couple with cancer, who are undergoing
                treatment such as chemotherapy, radiotherapy or even surgery for
                the same. These treatments may be toxic for the eggs/sperms or
                may decrease the ovarian reserve in women or the sperm counts in
                men. So Fertility preservation is done in such conditions.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>
                Fertility preservation may be done in women/couple’s who want to
                postpone their pregnancy.
              </p>
            </div>
            <div className="mb-2 p-4">
              <h2 className="text-center text-blue">
                What are the options for Fertility Preservation at Kangaroo Care
                Fertility
              </h2>
              <br />
              <p>
                <i className="fas fa-check-circle me-2"></i>
                Being the best Fertility preservation center in Vijayanagar
                Bangalore, we provide fertility preservation in the form of
                freezing eggs/embryos in women with serious illnesses such as
                cancer that will potentially risk damage to your eggs from
                chemotherapy, radiotherapy or other treatments, including
                surgery.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>
                We provide option for freezing eggs in women who are not ready
                to have a baby right now.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>
                We freeze sperms of men who have cancer or undergoing any
                treatment which is going to be lethal to the sperms.
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mb-2 mx-2 p-4 card  d-none d-md-block"
            id="service-link-card"
          >
            <h2 className="text-blue">Important Links</h2>
            <br />
            <ul className="ms-5">
              <li>
                <Link to="/treatments/ovulation-induction">
                  <span className="hand-link">&#9758;</span> &nbsp; Ovulation
                  Induction
                </Link>
              </li>
              <li>
                <Link to="/treatments/intrauterine">
                  <span className="hand-link">&#9758;</span> &nbsp; Intrauterine
                  Insemination (IUI)
                </Link>
              </li>
              <li>
                <Link to="/treatments/invitro-fertilization">
                  <span className="hand-link">&#9758;</span> &nbsp; In Vitro
                  Fertilization
                </Link>
              </li>
              <li>
                <Link to="/treatments/intracytoplasmic">
                  <span className="hand-link">&#9758;</span> &nbsp;
                  Intracytoplasmic Sperm Injection{" "}
                </Link>
              </li>
              <li>
                <Link to="/treatments/genetic-counselling-and-testing">
                  <span className="hand-link">&#9758;</span> &nbsp; Genetic
                  Counselling and Testing
                </Link>
              </li>
              <li>
                <Link to="/treatments/varicocele">
                  <span className="hand-link">&#9758;</span> &nbsp; Varicocele
                </Link>
              </li>
              <li>
                <Link to="/treatments/tesa-pesa">
                  <span className="hand-link">&#9758;</span> &nbsp; TESA/ PESA
                </Link>
              </li>
              <li>
                <Link to="/treatments/fertility-preservation">
                  <span className="hand-link">&#9758;</span> &nbsp; Fertility
                  Preservation
                </Link>
              </li>
              <li>
                <Link to="/treatments/Donor">
                  <span className="hand-link">&#9758;</span> &nbsp; Donor
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* -------------------------advantage cards---------------- */}

      {/* <div className="container-fluid my-4">
        <div className="row justify-content-center" id="scards">
          <h2 className="text-center">Advantages of Ovulation Induction</h2>
          <br />
          <br />
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Affordable</strong>
              </p>
            </div>
            <p>
              Ovulation Induction involves oral medications and sometimes
              injections and thus is not expensive.
            </p>
          </div>
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Non-invasive</strong>
              </p>
            </div>
            <p>It does not involve any invasive procedures.</p>
          </div>
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Suitability</strong>
              </p>
            </div>
            <p>
              It is most suitable in women with PCOS, anovulation, mild-moderate
              endometriosis, unexplained infertility.
            </p>
          </div>
        </div>
      </div> */}

      {/* ---------------------------faq------------------------ */}

      <div className="accordion container-fluid my-5 " id="accordionExample">
        <h2 className="text-center text-blue">FAQs</h2>
        <br />
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              • How do you preserve fertility ?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Preserving fertility involves, retrieving and freezing human
              reproductive tissue for later use in IUI or IVF/ICSI. We can
              cryopreserve eggs, sperm, embryo, ovarian tissue.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              • Can sperms be preserved and for how long ?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Sperms can be cryopreserved in men who may not be available for
              treatment, in cancer patients,etc. Sperms can be cryopreserved for
              several years.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              • Who can freeze their eggs ?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Eggs are frozen in women who want to plan their pregnancy at a
              later date. Both married and unmarried women can store their eggs.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              • How long is the fertility preservation in women?
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              The procsess takes around 2 weeks..
            </div>
          </div>
        </div>
        {/* <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              • Can IUI be done with Ovulation induction?
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Yes, IUI can be done with ovulation induction. Results are better
              when IUI is done along with ovulation induction.
            </div>
          </div>
        </div> */}
      </div>
      {/* ----------------some heading and para----------- */}
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Why choose Kangaroo Care Fertility for Fertility Preservation in
                Bangalore?
              </h2>
              <p>
                Kangaroo Care Fertility is one of the best embryo preservation
                centres in Bangalore. We have the best facilities with high-end
                advanced labs making it the best fertility preservation hospital
                in Bangalore.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Best Fertility Preservation Center in Bangalore
              </h2>
              <p>
                Kangaroo Care Fertility, is one of the best embryo freezing
                centers in Bangalore. Women suffering from serious illnesses or
                women who are not ready to have a baby can freeze their eggs.
                Not only for women, but even men can store sperm in cases where
                they are undergoing any treatment which is going to be lethal to
                the sperms. Kangaroo Care Fertility has the best facilities with
                high end advanced labs making it the best fertility preservation
                hospital in Bangalore.
              </p>
              <p>
                We also provide treatments like Ovulation Induction, IVF, IUI,
                ICSI, genetic counseling & testing, Varicocele treatment,
                TESA/PESA &nbsp;
                <a href="https://kangaroocarefertility.com/treatments/ovulation-induction">
                  Ovulation Induction,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/invitro-fertilization">
                  IVF treatment,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intrauterine">
                  IUI,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intracytoplasmic">
                  ICSI,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/genetic-counselling-and-testing">
                  genetic counseling & testing,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/varicocele">
                  varicocele treatment,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/tesa-pesa">
                  TESA/PESA &nbsp;
                </a>{" "}
                for female and male infertility problems.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
