import React from "react";
import rhea from "../assets/img/doctor/rhea-arun-new.jpg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function Rhea() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName: "",
    Description: "",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className="banner-header text-center py-5">
            <h2 style={{ color: '#fff', position: "relative", top: "100px" }}>Dr. DIVYASREE D</h2>
        </div>
    </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={rhea} className="card-img-top" alt="Dr. Divyashree D" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>M.Sc </p>
            <br />
            <p>
              Department : <span>EMBRYOLOGIST</span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Bangalore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Toll-free number @ 1800-425-4500</p>
            <br />
          </div>

          <div className="col-md-7">
            <h1 className="text-blue">MS. RHEA ARUN</h1>
            <p>
              <strong>EMBRYOLOGIST</strong>
            </p>

            <h3>PROFILE</h3>
            <p>
              A highly skilled and dedicated Junior Embryologist with over two
              years of practical experience in clinical embryology. Well-versed
              in WHO protocols and ESHRE guidelines, with a strong emphasis on
              optimizing fertilization, cleavage, and blastocyst conversion
              rates. Possesses exceptional attention to detail, strong
              organizational abilities, and the capacity to perform effectively
              in high-pressure laboratory settings.
            </p>
            <h3>
              <strong>Qualification:</strong>
            </h3>

            <p>
              <ul>
                <li>
                  Master of Science (M.Sc.) in Clinical Embryology – JSS Medical
                  College, Mysore
                </li>
                <li>
                  Bachelor of Science (B.Sc.) in Microbiology – JSS Medical
                  College, Mysore
                </li>
              </ul>
            </p>
            <h3>
              <strong>Work Experience:</strong>
            </h3>
            <p>
              <ul>
                <li>
                  Junior Clinical Embryologist – Chitras IVF & Fertility Center
                </li>
                <li>
                  Junior Clinical Embryologist – Motherhood Hospital, Bangalore
                </li>
                <li>Trainee Embryologist – Motherhood Hospital, Bangalore</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
