import React, { useEffect, useState } from "react";
import styles from "./branchDoctors.module.css";
import CustomTitle from "../../../CustomTitle";
import { useLocation, Link, useParams } from "react-router-dom";
import doctorsData from "../../../../json/doctors.json";
import branchData from "../../../../json/locations.json";

export default function BranchDoctors() {
  const { branchLink } = useParams();
  const { location } = useLocation();

  const [data, setData] = useState([]);
  const [dataBranch, setDataBranch] = useState([]);

  useEffect(() => {
    if (branchLink) {
      const docData = doctorsData.filter((item) =>
        item.branches.includes(branchLink)
      );
      const branchDataFilter = branchData.filter(
        (item) => item.titleLink == branchLink
      );
      setDataBranch(branchDataFilter);

      setData(docData);
    }
  }, [location]);
  const helmetDescriptions = {
    titleName:
      "Best Fertility and IVF Specialists in Bangalore – Expert Doctors",
    Description:
      "Find the best fertility and IVF specialists in Bangalore. Expert doctors offering advanced treatments for infertility, IUI, IVF, and reproductive health care.",
  };

  return (
    <section>
      <CustomTitle
        href="https://kangaroocarefertility.com/doctors/fertility-specialist-in-bangalore"
        customValue={helmetDescriptions}
      />

      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Best Fertility Specialist in Bangalore
          </li>
        </ol>
      </nav>
      <h1 className="text-center mb-2" style={{ color: "#7e52a0" }}>
        {dataBranch[0]?.pageTitle}
      </h1>
      <section className={styles.doctorItems}>
        <section className="container">
          <section className={styles.doctorItems}>
            {data.length > 0 &&
              data.map((item) => {
                return (
                  <article className={styles.doctorItem}>
                    <div>
                      <img src={`/images/doctor/${item.image}`} />
                    </div>
                    <div className={styles.doctorItem_content}>
                      <div>
                        <h3>
                          {item.isDoctor && "Dr."} {item.firstname}{" "}
                          {item.lastname}{" "}
                        </h3>
                        <p className={styles.doctor_qualification}>
                          <span> {item.isConsultant && "CONSULTANT -"} </span>{" "}
                          {item.designation}
                        </p>
                        <p>{item.qualification}</p>
                      </div>
                      <Link to={item.link} className="havingbtnme">
                        More Information
                      </Link>
                    </div>
                  </article>
                );
              })}
          </section>
        </section>
      </section>
    </section>
  );
}
