import React from "react";
import doc3 from "../assets/img/doctor/dr-divyashree.png";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function Divyasree() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName:
      "Dr. Divyasree D | Fertility Specialist | Kangaroo Care Fertility ",
    Description:
      "Experience compassionate guidance and leading-edge therapies with Dr. Divyasree D's Reproductive Medicine expertise. Book a consultation today",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className="banner-header text-center py-5">
            <h2 style={{ color: '#fff', position: "relative", top: "100px" }}>Dr. DIVYASREE D</h2>
        </div>
    </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={doc3} className="card-img-top" alt="Dr. Divyashree D" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>MBBS, MS OBG, FRM</p>
            <br />
            <p>
              Department : <span>Infertility And Ivf</span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Bangalore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Toll-free number @ 1800-425-4500</p>
            <br />
          </div>

          <div className="col-md-7">
            <h1 className="text-blue">Dr. DIVYASREE D</h1>
            <p>
              <strong>FELLOWSHIP IN REPRODUCTIVE MEDICINE</strong>
            </p>
            <p>
              Dr. Divyasree is practicing Obstetrician and Gynecologist with
              experience of more than 12 yrs and exclusive experience in
              treating infertile couple for the past 8 years.
            </p>
            <p>
              She completed her MBBS from prestigious Gandhi Medical College,
              Hyderabad and did her post graduation in Obstetrics and Gynecology
              from CAIMS Karimnagar, Andra Pradesh. She has done her fellowship
              in Reproductiv Medicine from CRAFTS Hospital and Research
              Institute, Kerala. She is trained in minimal Invasive Surgeries,
              at Bangalore.
            </p>
            <p>
              Dr. Divyasree is specialized in treating infertile couple with
              PCOS, Poor Ovarian Reserve, Recurrent IVF Failures and Recurrent
              Pregnancy Loss.
            </p>
            <p>
              She has given good pregnancy rates of 65% - 70% and high take-home
              baby rates in infertile couples.
            </p>
            <h3>QUALIFICATION</h3>
            <p>MBBS, MS (OBG)</p>
            <p>Fellowship in Reproductive Medicine (FRM)</p>
            <h3>WORK EXPERIENCE</h3>
            <p>
              Consultant at Garbhagudi IVF center, Bangalore from 2018 till
              2021.
            </p>
            <p>
              Reproductive Medicine unit at CRAFTS hospital, Kerala 2017 t0
              2018.
            </p>
            <p>
              Assistant Professor in dept of OBG at PESIMSR, Kuppam.2012 to
              2017.
            </p>
            <h3>RESEARCH AND PUBLICATIONS:</h3>
            <p>
              She has presented and published various clinical papers and case
              reports in National Journals and Conferences.
            </p>
            <p>
              Testicular sperms versus macs in patients with high DNA
              fragmentation index and their ICSI outcome - presentation at
              National Fertility Conference 2018.
            </p>
            <p>
              Laparoscopic Management of Large Ovarian Cyst – published in
              International Journal Reprod Contracept Obstet Gynecol 2017.
            </p>
            <p>
              Disengagement of the deeply engaged fetal head during caesarean
              section in advanced labour : patwardhan versus push method
              published in International Journal Reprod Contracept Obstet
              Gynecol 2015.
            </p>
            <h3>MEMBERSHIP:</h3>
            <p>Karnataka Medical Council</p>
            <p>Indian Fertility Society.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
