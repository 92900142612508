import React, { useEffect, useState } from "react";
import CustomTitle from "./CustomTitle";
import "./Contact.css";
import { useLocation, Link } from "react-router-dom";
import locations from "../json/locations.json";
export default function Contact() {
  const [branch, setBranch] = useState("");
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const helmetDescriptions = {
    titleName: "Contact Us | Kangaroo Care Fertility Hospital Bangalore",
    Description:
      "Empowering your fertility journey is our priority at Kangaroo Care Fertility. Contact us for compassionate guidance and cutting-edge treatments.",
  };
  useEffect(() => {
    setBranch(locations[0]);
  }, []);

  console.log(branch);

  return (
    <div>
      <CustomTitle
        href="https://kangaroocarefertility.com/contact"
        customValue={helmetDescriptions}
      />
      {/* <div id="contactUs-banner">
        <h2 className="heading-banner text-center">Contact US</h2>
      </div> */}
      {/* <div className="mx-1 p-1 border mb-sm-4 mb-3">
        <iframe
          title="Kangaroo Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d408.6718576014366!2d77.54217520974231!3d12.974164243744603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d19b50bd381%3A0xa7dcb435867f60e1!2sKangaroo%20Care%20Fertility!5e0!3m2!1sen!2sin!4v1626953229345!5m2!1sen!2sin"
          width="100%"
          height="350px"
          scrolling="no"
        ></iframe>
      </div> */}
      <div
        style={{ backgroundColor: "#F7F7F7" }}
        className="contact_form_wrapper"
      >
        <div className="content-box-contact container mb-sm-5 mb-2">
          <div className="row mb-sm-4 pe-0 g-0 align-items-center">
            {/* <div className="col-lg-12 col-sm-12 mb-sm-3">
            <div className="related-links">
              <div className="info">
                <div className="address d-flex align-items-center mb-sm-4 mb-2">
                  <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                  <div className="address-content ms-2">
                    <h1 className="text-blue"> Bangalore Contact Info </h1>
                    <span>Location</span>
                    <p>
                      #119, 8th cross MC Layout, <br />
                      Vijaynagar, Bangalore - 560040
                    </p>
                  </div>
                </div>
                <div className="email d-flex align-items-center mb-sm-4 mb-2">
                  <i className="fas fa-envelope mb-3" aria-hidden="true"></i>
                  <div className="email-content">
                    <span>Email</span>
                    <p>fertilityblr@kangaroocareindia.com</p>
                  </div>
                </div>
                <div className="phone d-flex align-items-center mb-sm-4 mb-2">
                  <i className="fas fa-phone-alt mb-3" aria-hidden="true"></i>
                  <div className="phone-content">
                    <span> Toll Free Number </span>
                    <p>
                      
                      <a href="tel:18004254500" style={{ color: "#ea3288" }}>
                        1800-425-4500
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="info">
                <div className="address d-flex align-items-center mb-sm-4 mb-2">
                  <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                  <div className="address-content ms-2">
                    <h2 className="text-blue"> Mysore Contact Info</h2>
                    <span>Location</span>
                    <p>
                      #505, Kalidasa road, Vijayanagar 1st stage, Opp. MUDA
                      Complex, Mysore - 570017.
                    </p>
                  </div>
                </div>
                <div className="email d-flex align-items-center mb-sm-4 mb-2">
                  <i className="fas fa-envelope mb-3" aria-hidden="true"></i>
                  <div className="email-content">
                    <span>Email</span>
                    <p>info@kangaroocareindia.com</p>
                  </div>
                </div>
                <div className="phone d-flex align-items-center mb-sm-4 mb-2">
                  <i className="fas fa-phone-alt mb-3" aria-hidden="true"></i>
                  <div className="phone-content">
                    <span> Toll Free Number </span>
                    <p>
                      
                      <a href="tel:18004254500" style={{ color: "#ea3288" }}>
                        1800-425-4500
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="info">
                <div className="address d-flex align-items-center mb-sm-4 mb-2">
                  <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                  <div className="address-content ms-2">
                    <h2 className="text-blue"> Ramanagara Contact Info</h2>
                    <span>Location</span>
                    <p>
                      No.32 of Vivekanandanagar, Block-3, North B.M. Road,
                      Ramanagara -562 159 Karnataka
                    </p>
                  </div>
                </div>
                <div className="email d-flex align-items-center mb-sm-4 mb-2">
                  <i className="fas fa-envelope mb-3" aria-hidden="true"></i>
                  <div className="email-content">
                    <span>Email</span>
                    <p>info@kangaroocareindia.com</p>
                  </div>
                </div>
                <div className="phone d-flex align-items-center mb-sm-4 mb-2">
                  <i className="fas fa-phone-alt mb-3" aria-hidden="true"></i>
                  <div className="phone-content">
                    <span> Toll Free Number </span>
                    <p>
                      
                      <a href="tel:18004254500" style={{ color: "#ea3288" }}>
                        1800-425-4500
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            <div className="center_contact">
              <div className="center_names">
                <ul>
                  {locations.map((item) => {
                    return (
                      <li
                        className={`text-blue ${
                          branch.id === item.id ? "center_active" : ""
                        }`}
                        key={item.id}
                        onClick={() => setBranch(item)}
                      >
                        <img
                          src={`/images/locations/${item.icon}`}
                          className="pFilter"
                        />
                        {item.title}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="center_map">
                <iframe
                  src={branch?.map}
                  style={{ border: 0 }}
                  allowFullDcreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className=" container  ">
          <div className="row">
            <div className="col-lg-5">
              <h3 className="text-blue" id="contactUs-title">
                Contact Us
              </h3>
              {/* <h3>Contact US</h3> */}
              <p>
                Kangaroo Care Fertility has helped thousands of families across
                the nation achieve their dream of parenthood. With a long
                history of successful outcomes, we’re committed to making your
                path to parenthood as seamless and joyful as possible. Bringing
                a new life into the world is a cherished milestone, and Kangaroo
                Care helps you achieve this with the best-in-business expert
                guidance and support. We would love to guide you and be your
                trusted partner in creating beautiful beginnings for your
                family!
              </p>
              <p>
                <a
                  className="text-blue"
                  href="mailto:fertilitymarketing@kangaroocareindia.com"
                >
                  <i className="fas fa-envelope-open me-2"></i>
                  fertilitymarketing@kangaroocareindia.com
                </a>
              </p>
              <div className="contact-number">
                <a href="tel:1800-425-4500" className="text-blue">
                  {" "}
                  <i className="fas fa-tty me-2"></i>Toll Free - 1800-425-4500
                </a>
              </div>
            </div>
            <div className="col-lg-7">
              <form
                action=""
                method="POST"
                className="row g-3 needs-validation contact_form"
                novalidate
              >
                <div className="text-center form-text my-md-3 mt-4"></div>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label for="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      pattern="[a-zA-Z ]{2,30}"
                      placeholder="Your Name"
                      autocomplete="off"
                      required
                    />
                    <div className="valid-feedback">It Looks good!</div>
                    <div className="invalid-feedback">
                      Please Enter your Name.
                    </div>
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <label for="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      pattern="[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      autocomplete="off"
                      required
                    />
                    <div className="valid-feedback">It Looks good!</div>
                    <div className="invalid-feedback">
                      Please Entet your Email.
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <label for="subject" className="form-label">
                    Subject
                  </label>
                  <div className="input-group has-validation">
                    <input
                      type="text"
                      className="form-control"
                      title="why are try to cantact with us."
                      name="subject"
                      id="subject"
                      pattern="[a-zA-Z ]{10,100}"
                      placeholder="Subject"
                      autocomplete="off"
                      required
                    />
                    <div className="valid-feedback">It Looks good!</div>
                    <div className="invalid-feedback">
                      Please choose a Subject.
                    </div>
                  </div>
                </div>
                <div className="row-mt-3">
                  <label for="message">Message</label>
                  <div className="input-group has-validation mt-1">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="5"
                      placeholder="Message have min length 50 and max length 1000"
                      pattern="[a-zA-Z ]{30,1000}"
                      autocomplete="off"
                      required
                    ></textarea>
                    <div className="valid-feedback">It Looks good!</div>
                    <div className="invalid-feedback">
                      Please Enter Your proper Message without using any specail
                      Character.
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexSwitchCheckChecked"
                      required
                    />
                    <label
                      className="form-check-label"
                      for="flexSwitchCheckChecked"
                    >
                      Agree
                      {/* <span>
                      <Link to="/maintainence">terms and conditions</Link>
                    </span> */}
                    </label>
                    <div className="valid-feedback">
                      You accepted our Privacy Policy
                    </div>
                    <div className="invalid-feedback">
                      You must agree before submitting.
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button className="btn btn-success" type="submit">
                    Submit form
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
