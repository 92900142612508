import React from "react";
import sachin from "../assets/img/doctor/sachin.jpg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function Sachin() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName: "",
    Description: "",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className="banner-header text-center py-5">
            <h2 style={{ color: '#fff', position: "relative", top: "100px" }}>Dr. DIVYASREE D</h2>
        </div>
    </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img
                src={sachin}
                className="card-img-top"
                alt="Dr. Sachin Dharwadka "
              />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>MBBS, MS, MCH</p>
            <br />
            <p>
              Department : <span>UROLOGIST</span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Mysore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> -Toll-free number @ 1800-425-4500</p>
            <br />
          </div>
          <div className="col-md-7">
            <h1 className="text-blue">DR. SACHIN DHARWADKAR</h1>
            <p>
              <strong>UROLOGIST</strong>
            </p>
            <h3>PROFILE:</h3>

            <span>
              Dr. Sachin Dharwadkar is a highly skilled urologist with expertise
              in diagnosing, evaluating, and treating various urological
              conditions across all subspecialties. He has a strong background
              in renal transplantation and a passion for teaching, having
              actively mentored both undergraduate and postgraduate students
            </span>
            <br />

            <br />
            <h3>QUALIFICATION</h3>

            <span>
              <ol>
                <li>
                  MBBS – JJM Medical College, Davangere (1998-2004) University:
                  RGUHS, Bangalore Registered at Karnataka Medical Council
                </li>
                <li>
                  MS – General Surgery (2006-2009) Mysore Medical College and
                  Research Institute, Mysore University: RGUHS, Bangalore
                </li>
                <li>
                  MCh – Urology (2013-2016) Bangalore Medical College and
                  Research Institute, Bangalore University: RGUHS, Bangalore
                </li>
              </ol>
            </span>
            <br />
            <br />
            <h3>WORK EXPERIENCE</h3>

            <span>
              <ol>
                <li>
                  Assistant Professor – General Surgery, AIMS, B G Nagar (Sept
                  2009 – June 2011)
                </li>
                <li>
                  Registrar – Department of Urology, Apollo Hospital, Chennai
                  (June 2011 – Sept 2011)
                </li>
                <li>
                  Assistant Professor – General Surgery, Tagore Medical College,
                  Chennai (Oct 2011 – July 2012)
                </li>
                <li>
                  Associate Consultant – Vikram Jeev Hospital, Mysore (Aug 2012
                  – July 2013)
                </li>
                <li>
                  Assistant Professor – Dept. of Urology, JSS Medical College
                  Hospital, Mysore (Aug 2016 – May 2019)
                </li>
                <li>
                  Associate Professor – Dept. of Urology, JSS Medical College
                  Hospital, Mysore (May 2019 – Present)
                </li>
              </ol>
            </span>
            <br />
            <br />
            <h3> RESEARCH & PUBLICATIONS</h3>

            <span>
              Presented a talk on "Second Opinion in Urology" at the South Zone
              Urology Conference, MS Ramaiah Medical College, Bangalore
            </span>
            <span>
              Chaired a session at the Karnataka Urology Association Conference,
              Davangere
            </span>
            <span>
              Presented papers and posters at state, zonal, and national
              conferences during post-graduation
            </span>
            <span>
              Attended and conducted multiple workshops in urology during
              training and as faculty at JSS Medical College, Mysore
            </span>

            <br />
            <br />
            {/* 
            <h3>CERTIFICATIONS</h3>
            <span>
              <ol>
                <li>Fellowship in Infertility (IMA)</li>
                <li>
                  Advanced Diploma in ART and Reproductive Medicine,
                  Schleswig-Holstein, Kiel, Germany
                </li>
                <li>Fellowship in Sexual Medicine, JSS Mysore</li>
                <li>
                  Advanced Laparoscopy Training, CEMAST, Govindkripa Laparoscopy
                  Center
                </li>
                <li>Ultrasound Training, Kuwait</li>
                <li>Ian-Donald Inter University Medical Ultrasound</li>
                <li>
                  DFOGSI Advanced Ultrasound Course, Radhakrishna Hospital,
                  Bangalore
                </li>
              </ol>
            </span>

            <br />
            <br />

            <h3>SUB- SPECIALITY INTERESTS </h3>

            <span>
              <ol>
                <li>PCOS management</li>
                <li>Fertility preservation</li>
                <li>
                  Fertility-enhancing gynecological surgeries: hysteroscopy,
                  laparoscopy for endometriosis, fibroid, uterine polyps,
                  ovarian cyst, hydrosalpinx, and tubal factor infertility
                </li>
              </ol>
            </span> */}

            {/* <ol></ol> */}
            {/* <p>
              Dr. Divyasree is practicing obstetrician and gynecologist with
              experience of more than 10 yrs and exclusive experience in
              treating infertile couple for the past 3 years.
            </p>
            <p>
              She completed her MBBS from prestigious Gandhi medical college,
              Hyderabad and did her post graduation in Obstetrics and Gynecology
              from CAIMS Karimnagar, Andra Pradesh. She has done her fellowship
              in reproductive medicine from CRAFTS hospital and research
              institute, Kerala. She is trained in minimal invasive surgeries,
              at Bangalore.
            </p>
            <p>
              Dr. Divyasree is specialized in treating infertile couple with
              PCOS, poor ovarian reserve, recurrent IVF failures and recurrent
              pregnancy loss.
            </p>
            <p>
              She has given good pregnancy rates and take home baby rate in
              infertile couple
            </p>
            <h3>QUALIFICATION</h3>
            <p>MBBS, MS (OBG)</p>
            <p>Fellowship in reproductive medicine</p>
            <h3>WORK EXPERIENCE</h3>
            <p>
              Consultant at Garbhagudi IVF center, Bangalore from 2018 till
              2021.
            </p>
            <p>
              Reproductive medicine unit at CRAFTS hospital, Kerala 2017 t0
              2018.
            </p>
            <p>
              Assistant professor in dept of OBG at PESIMSR, Kuppam.2012 to
              2017.
            </p>
            <h3>RESEARCH AND PUBLICATIONS:</h3>
            <p>
              She has presented and published various clinical papers and case
              reports in national journals and conferences.
            </p>
            <p>
              Testicular sperms versus macs in patients with high dna
              fragmentation index and their ICSI outcome - presentation at
              national fertility conference 2018.
            </p>
            <p>
              Laparoscopic management of large ovarian cyst – published in
              international journal Reprod contracept Obstet Gynecol 2017.
            </p>
            <p>
              Disengagement of the deeply engaged fetal head during caesarean
              section in advanced labour : patwardhan versus push method
              published in international journal reprod contracept obstet
              gynecol 2015.
            </p>
            <h3>MEMBERSHIP:</h3>
            <p>Karnataka Medical Council</p>
            <p>She is a life time member in Indian fertility society.</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
