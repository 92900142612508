import React from "react";
import { NavLink as Link } from "react-router-dom";
import blog1 from "../assets/img/media/blogs/blog-diet-banner.jpg";
import CustomTitle from "../CustomTitle";
import BlogData from "./blog.json";
export default function Blog() {
  const BlogContent = (props) => {
    // BlogData.map((item) => {
    //   console.log(
    //     `<url> <loc>https://kangaroocarefertility.com/media/blogs/${item.link}</loc> </url>`
    //   );
    // });
    // console.log(props.link);

    return (
      <>
        <div
          className="container col-md-4 blogCard card"
          style={{ maxWidth: "24rem" }}
        >
          <Link to={`${props.link}`}>
            <img
              src={`/${props.image}`}
              className="card-img-top"
              alt="Egg tray"
            />
          </Link>
          <div className="card-body my-2">
            <Link to={`${props.link}`}>
              <h2 className="card-title text-blue">{props.title}</h2>{" "}
            </Link>
            <p className="card-text">{props.text}</p>{" "}
            <h6 style={{ float: "right" }}>Date: {props.date}</h6>
          </div>
        </div>
      </>
    );
  };
  const helmetDescriptions = {
    titleName: "Expert IVF & Fertility Tips – Kangaroo Care Blogs",
    Description:
      "Get expert IVF and fertility tips from Kangaroo Care Blogs. Learn about treatments, success factors, and pregnancy guidance to support your parenthood journey.",
  };
  return (
    <>
      <CustomTitle
        href="https://kangaroocarefertility.com/media/blogs"
        customValue={helmetDescriptions}
      />
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Media
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Blog
          </li>
        </ol>
      </nav>
      <h1 className="h1-top-text">Blogs</h1>
      <div className="blg container offset-md-1 row col-md-10 d-md-flex text-left ">
        {BlogData.map((val) => {
          return (
            // <Link to={`/blog-test1/${val.id}`}>
            <BlogContent
              id={val.id}
              text={val.excert}
              image={val.image}
              title={val.title}
              link={val.link}
              date={val.date}
              seoTitle={val.seoTitle}
            />
            // </Link>
          );
        }).reverse()}
      </div>
    </>
  );
}
