import React from "react";
import "./image.css";

import images from "./images.json";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";
import ImageShow from "../../UI/ImageShow/ImageShow";

export default function Image() {
  const [show, setShow] = React.useState(null);

  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // const images = array.map((key, index) => {
  //   return (
  //     <div className="col-md-4 mb-3 mb-md-5">
  //       <div className="card-img" id="card-img">
  //         {/* Add Your Image here from database */}
  //         <img
  //           src={require(`./images/${key.image}.jpg`)}
  //           src={key.image}
  //           alt="..."
  //         />
  //         <div className="card-title text-center py-2">
  //           {/* Add your Image text from database */}
  //           <span>Lorem</span>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // });
  const helmetDescriptions = {
    titleName: "Image Gallery | Kangaroo Care Fertility",
    Description:
      " View our photo Gallery to see the hospital environment and services.",
  };
  const mysoreImages = [
    {
      id: 1,
      img: "gallery_mysore_1.jpg",
    },
    {
      id: 2,
      img: "gallery_mysore_2.jpg",
    },
    {
      id: 3,
      img: "gallery_mysore_3.jpg",
    },
    {
      id: 4,
      img: "gallery_mysore_4.jpg",
    },

    {
      id: 6,
      img: "gallery_mysore_6.jpg",
    },

    {
      id: 8,
      img: "gallery_mysore_8.jpg",
    },
  ];
  const ramanagaraImages = [
    {
      id: 6,
      img: "som4.jpg",
    },

    {
      id: 2,
      img: "som1.jpg",
    },
    {
      id: 3,
      img: "som2.jpg",
    },
    {
      id: 4,
      img: "som3.jpg",
    },

    {
      id: 1,
      img: "som7.JPG",
    },
    {
      id: 8,
      img: "som6.jpg",
    },
  ];
  function hanldleImgClick(img) {
    // let imgPath = `${path}${img}`;
    setShow(img);
  }
  return (
    <>
      {show && (
        <ImageShow
          show={show}
          setShow={setShow}
          customPath="/images/gallery/"
        />
      )}
      <CustomTitle
        href="https://kangaroocarefertility.com/gallery/images"
        customValue={helmetDescriptions}
      />
      <h1 className="h1-top-text mb-3 image-h1-top-text">
        Photo Gallery of Kangaroo Care Fertility
      </h1>
      <div className="container">
        <div className="row">
          <h2 className="h1-top-text mb-3 image-h1-top-text">Bangalore</h2>

          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/gallery/kc1.jpg"
                alt="..."
                onClick={() => hanldleImgClick("kc1.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/gallery/kc2.jpg"
                alt="..."
                onClick={() => hanldleImgClick("kc2.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>

          {/* <h2 className="h1-top-text mb-3 image-h1-top-text">Mysore </h2> */}

          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/gallery/event-212.jpg"
                alt="..."
                onClick={() => hanldleImgClick("event-212.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/gallery/kc3.jpg"
                alt="..."
                onClick={() => hanldleImgClick("kc3.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>

          {/* <h2 className="h1-top-text mb-3 image-h1-top-text">Ramanagara </h2> */}

          {/* <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/gallery/kc5.jpg"
                alt="..."
                onClick={() => hanldleImgClick("kc5.jpg")}
              />
              <div className="card-title text-center py-2">
                <span>Lorem</span>
              </div>
            </div>
          </div> */}

          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/gallery/kc7.jpg"
                alt="..."
                onClick={() => hanldleImgClick("kc7.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3 mb-md-5">
            <div className="card-img" id="card-img">
              <img
                src="/images/gallery/kc10.jpg"
                alt="..."
                onClick={() => hanldleImgClick("kc10.jpg")}
              />
              <div className="card-title text-center py-2">
                {/* <span>Lorem</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <h2 className="h1-top-text mb-3 image-h1-top-text">Mysore</h2>

          {mysoreImages.map((item, index) => {
            return (
              <div className="col-md-4 mb-3 mb-md-5" key={index}>
                <div className="card-img" id="card-img">
                  <img
                    src={`/images/gallery/${item.img}`}
                    alt="..."
                    onClick={() => hanldleImgClick(item.img)}
                  />
                  <div className="card-title text-center py-2">
                    {/* <span>Lorem</span> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="container">
        <div className="row">
          <h2 className="h1-top-text mb-3 image-h1-top-text">Ramanagara</h2>

          {ramanagaraImages.map((item, index) => {
            return (
              <div className="col-md-4 mb-3 mb-md-5" key={index}>
                <div className="card-img" id="card-img">
                  <img
                    src={`/images/gallery/${item.img}`}
                    alt="..."
                    onClick={() => hanldleImgClick(item.img)}
                  />
                  <div className="card-title text-center py-2">
                    {/* <span>Lorem</span> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
